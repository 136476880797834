import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('../views/dashboard/Dashboard')
const NewDashboard = () => import('@/views/NewDashboard')
const Login = () => import('@/views/Login')
const Register = () => import('@/views/Register')
const ForgotPassword = () => import('@/views/ForgotPassword')
const Staff = () => import('@/views/profiles/Staff')
const Customer = () => import('@/views/profiles/Customer')
const ViewCustomer = () => import('@/views/profiles/viewCustomer')
const createCustomer = () => import('@/views/profiles/createCustomer')
const createBusiness = () => import('@/views/profiles/createBusiness')


const ViewBusiness = () => import('@/views/profiles/viewBusiness')
const ViewTransactions = () => import('@/views/profiles/viewTransactions')

const Business = () => import('@/views/profiles/Business')
const Reports = () => import('@/views/Reports')
const Loans = () => import('@/views/Loans')
const Credit = () => import('@/views/credit')
const Savingsproduct = () => import('@/views/products/savings')
const Loansproduct = () => import('@/views/products/loans')
const Ledgers = () => import('@/views/financial/ledgers')
const Transactions = () => import('@/views/financial/transactions')
const Subscription = () => import('@/views/subscription')












const Card = () => import('../views/cards/Cards')
const Category = () => import('../views/inventroy/Category')
const Productgroup = () => import('../views/inventroy/Productgroup')
const Items = () => import('../views/inventroy/Items')

const Users = () => import('../views/basecomponents/users/Users')









// // Views - Pages
// const Page404 = () => import('@/views/pages/Page404')
// const Page500 = () => import('@/views/pages/Page500')




Vue.use(Router)

export default new Router({
  // mode: 'hash', // https://router.vuejs.org/api/#mode
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
 
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/login',
      name: 'Home',
      component: {
        render (c) { return c('router-view') }

      },
      children: [
        {
          path: '/login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }, 
        {
          path: 'forgotpassword',
          name: 'ForgotPassword',
          component: ForgotPassword
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: 
            NewDashboard
        }, 
        {
          path: 'subscription',
          name: 'Subscription',
          component: 
            Subscription
        }, 
        {
          path: 'user',
          name: 'Users',
          component: 
            Users
        }, 
        {
            path: '/profiles/staff',
            name: 'Staff',
            component: Staff  
        },
         {
            path: '/profiles/personal',
            name: 'Customer',
            component: Customer  
        },
          {
            path: '/profiles/viewcustomer',
            name: 'ViewCustomer',
            component: ViewCustomer  
        },
        {
          path: '/profiles/createCustomer',
          name: 'createCustomer',
          component: createCustomer
       },
       {
        path: '/profiles/createBusiness',
        name: 'createBusiness',
        component: createBusiness
     },
        {
          path: '/profiles/viewbusiness/:id',
          name: 'ViewBusiness',
          component: ViewBusiness 
      },
      {
        path: '/profiles/viewTransactions/:id',
        name: 'ViewTransactions',
        component: ViewTransactions 
    },
        {
          path: '/profiles/business',
          name: 'Business',
          component: Business  
      },
      {
        path: '/loans',
        name: 'Loans',
        component: Loans
    },
    {
      path: '/credit',
      name: 'Credit',
      component: Credit
  },
        
        {
            path: '/products/Savingsproduct',
            name: 'Savingsproduct',
            component: Savingsproduct
         },
         {
          path: '/products/Loansproduct',
          name: 'Loansproduct',
          component: Loansproduct
       },
       {
        path: '/financial/Ledgers',
        name: 'Ledgers',
        component: Ledgers
     },
     {
      path: '/financial/transactions',
      name: 'Transactions',
      component: Transactions
   },

       {
        path: '/reports',
        name: 'Reports',
        component: Reports
     },
      ]
      
    },

    // {
    //   path: '/boportal',
    //   redirect:'/boportal/bodashboard',
    //   name: 'BoPortal',
    //   component: {
    //     render (c) { return c('router-view') },
    //     children: [
    //       {
    //         path: '/boportal/bodashboard',
    //         name: 'BoBashboard',
    //         component: BoDashboard
    //       }
    //     ]
    //   },
      
    // }
    // {
    //   path: '/pages',
    //   redirect: '/pages/404',
    //   name: 'Pages',
    //   component: {
    //     render (c) { return c('router-view') }
    //   },
    //   children: [
    //     {
    //       path: '404',
    //       name: 'Page404',
    //       component: Page404
    //     },
    //     {
    //       path: '500',
    //       name: 'Page500',
    //       component: Page500
    //     },
    //   ]
    // }

        
     
    
  ]
}

